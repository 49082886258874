import _stream from "stream";
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
const {
  PassThrough
} = _stream;

exports = options => {
  options = Object.assign({}, options);
  const {
    array
  } = options;
  let {
    encoding
  } = options;
  const buffer = encoding === "buffer";
  let objectMode = false;

  if (array) {
    objectMode = !(encoding || buffer);
  } else {
    encoding = encoding || "utf8";
  }

  if (buffer) {
    encoding = null;
  }

  let len = 0;
  const ret = [];
  const stream = new PassThrough({
    objectMode
  });

  if (encoding) {
    stream.setEncoding(encoding);
  }

  stream.on("data", chunk => {
    ret.push(chunk);

    if (objectMode) {
      len = ret.length;
    } else {
      len += chunk.length;
    }
  });

  stream.getBufferedValue = () => {
    if (array) {
      return ret;
    }

    return buffer ? Buffer.concat(ret, len) : ret.join("");
  };

  stream.getBufferedLength = () => len;

  return stream;
};

export default exports;