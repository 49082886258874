import _pump from "pump";
import _bufferStream from "./buffer-stream";
var exports = {};
const pump = _pump;
const bufferStream = _bufferStream;

class MaxBufferError extends Error {
  constructor() {
    super("maxBuffer exceeded");
    this.name = "MaxBufferError";
  }

}

function getStream(inputStream, options) {
  if (!inputStream) {
    return Promise.reject(new Error("Expected a stream"));
  }

  options = Object.assign({
    maxBuffer: Infinity
  }, options);
  const {
    maxBuffer
  } = options;
  let stream;
  return new Promise((resolve, reject) => {
    const rejectPromise = error => {
      if (error) {
        // A null check
        error.bufferedData = stream.getBufferedValue();
      }

      reject(error);
    };

    stream = pump(inputStream, bufferStream(options), error => {
      if (error) {
        rejectPromise(error);
        return;
      }

      resolve();
    });
    stream.on("data", () => {
      if (stream.getBufferedLength() > maxBuffer) {
        rejectPromise(new MaxBufferError());
      }
    });
  }).then(() => stream.getBufferedValue());
}

exports = getStream;

exports.buffer = (stream, options) => getStream(stream, Object.assign({}, options, {
  encoding: "buffer"
}));

exports.array = (stream, options) => getStream(stream, Object.assign({}, options, {
  array: true
}));

exports.MaxBufferError = MaxBufferError;
export default exports;
export const buffer = exports.buffer,
      array = exports.array;
const _MaxBufferError = exports.MaxBufferError;
export { _MaxBufferError as MaxBufferError };